const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  cake: {
    symbol: 'PINKS',
    address: {
      56: '0x702b3f41772e321aacCdea91e1FCEF682D21125D',
      97: '0x702b3f41772e321aacCdea91e1FCEF682D21125D',
    },
    decimals: 18,
    projectLink: 'https://pinkswap.finance/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pinkswap.finance/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  pinke: {
    symbol: 'PinkE',
    address: {
      56: '0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a',
      97: '0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a',
    },
    decimals: 9,
    projectLink: 'https://pinkswap.finance/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059ff775485246999027b3197955',
      97: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: 'https://pinkswap.finance/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      97: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    decimals: 18,
    projectLink: 'https://pinkswap.finance/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      97: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    decimals: 18,
    projectLink: 'https://pinkswap.finance/',
  }
}

export default tokens
