import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
 {
  pid: 0,
  lpSymbol: 'PINKS-BNB',
  lpAddresses: {
    97: '0x61c960D0337f1EfE46BC7B1110bA8C4e60DD2017',
    56: '0x61c960D0337f1EfE46BC7B1110bA8C4e60DD2017',
  },
  token: tokens.cake,
  quoteToken: tokens.wbnb,
},
{
  pid: 1,
  lpSymbol: 'BNB-BUSD LP',
  lpAddresses: {
    97: '0x2E4BaE64Cc33eC8A7608930E8Bd32f592E8a9968',
    56: '0x2E4BaE64Cc33eC8A7608930E8Bd32f592E8a9968',
  },
  token: tokens.wbnb,
  quoteToken: tokens.busd,
},
{
  pid: 2,
  lpSymbol: 'PINKE-BNB LP',
  lpAddresses: {
    97: '0xB9eFbD2Bb41f7A2136BF2B21e8B26641651adef9',
    56: '0xB9eFbD2Bb41f7A2136BF2B21e8B26641651adef9',
  },
  token: tokens.pinke,
  quoteToken: tokens.wbnb,
},
{
  pid: 3,
  lpSymbol: 'USDT-BUSD LP',
  lpAddresses: {
    97: '0xefd49d669d73Acf4dF4dF7C677F689Fc6ca6ecaB',
    56: '0xefd49d669d73Acf4dF4dF7C677F689Fc6ca6ecaB',
  },
  token: tokens.usdt,
  quoteToken: tokens.busd,
},
{
  pid: 4,
  lpSymbol: 'ETH-BNB LP',
  lpAddresses: {
    97: '0x5aB38077E9C5f9980bbabf68679B7E34137a51Af',
    56: '0x5aB38077E9C5f9980bbabf68679B7E34137a51Af',
  },
  token: tokens.eth,
  quoteToken: tokens.wbnb,
},
{
  pid: 5,
  lpSymbol: 'BTCB-BNB LP',
  lpAddresses: {
    97: '0xC4dBFe8860bd39F0F8281B7DE8eE53730c9a44e1',
    56: '0xC4dBFe8860bd39F0F8281B7DE8eE53730c9a44e1',
  },
  token: tokens.btcb,
  quoteToken: tokens.wbnb,
},
{
  pid: 6,
  lpSymbol: 'USDT-BNB LP',
  lpAddresses: {
    97: '0xb390F799ba5f75e0BB15014A9a34a0dEC6760E1E',
    56: '0xb390F799ba5f75e0BB15014A9a34a0dEC6760E1E',
  },
  token: tokens.usdt,
  quoteToken: tokens.wbnb,
},
]

export default farms
